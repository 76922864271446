import React from 'react';
import ComponentContainer from './ComponentContainer';
import { IComponentElement } from '../lib/types';

export default function Buttons() {
    return (
        <div>
            <ComponentContainer component={PrimaryButton} />
            <ComponentContainer component={WhiteButton} />
            <ComponentContainer component={AddToCartButton} />
            <ComponentContainer component={FullWidth} />
        </div>
    );
}

// nr-primary
const PrimaryButton = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <button ref={codeRef as React.RefObject<HTMLButtonElement>} className="px-4 py-2 bg-nr-black80 text-white uppercase">Primary Button</button>
            {ComponentButtons}
        </div>
    );
}

const WhiteButton = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <div className="p-2 bg-nr-pink">
                <button ref={codeRef as React.RefObject<HTMLButtonElement>} className="px-4 py-2 bg-transparent text-white border border-white uppercase">White Button</button>
            </div>
            {ComponentButtons}
        </div>
    );
}


const AddToCartButton = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <button ref={codeRef as React.RefObject<HTMLButtonElement>} className="px-4 py-2 bg-nr-bark60 text-white uppercase">Add To Cart</button>
            {ComponentButtons}
        </div>
    );
}

const FullWidth = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <button ref={codeRef as React.RefObject<HTMLButtonElement>} className="px-4 py-2 bg-nr-black80 text-white uppercase w-full">Full Width Button</button>
            {ComponentButtons}
        </div>
    );
}

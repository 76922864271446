import React from 'react';
import ComponentContainer from './ComponentContainer';
import { IComponentElement } from '../lib/types';

export default function HeroSection() {
    return (
        <div>
            <ComponentContainer component={HeroComponent} />
        </div>
    );
}

const HeroComponent = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <div className="min-h-[600px] w-full flex flex-col justify-center bg-no-repeat bg-cover" ref={codeRef as React.RefObject<HTMLImageElement>} style={{backgroundImage: `url(assets/hero.png)`}}>
                <div className="container">
                    <h1 className="text-5xl font-bold font-playfair text-nr-gray2 mb-2">The Dress Refresh</h1>
                    <p className="text-lg text-nr-gray2 font-sans">Shop dresses for your sunny state of mind,<br />from beach to brunch.</p>
                </div>
            </div>
            {ComponentButtons}
        </div>
    );
};

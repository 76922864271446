import React, { useCallback, useRef, useState } from 'react';
import { toast } from "sonner";
import { MdOutlineCodeOff, MdOutlineContentCopy } from 'react-icons/md';
import { BsCodeSlash } from 'react-icons/bs';
import { IComponent } from '../lib/types';
import { Button } from '../components/ui/button';

const CopyButton = ({ onClick }: { onClick: () => void }) => {
    return (
        <Button onClick={onClick} className="text-xs h-10 w-10 p-0">
            <MdOutlineContentCopy />
        </Button>
    );
};

const ShowCodeButton = ({ onClick, isWrapped }: { onClick: () => void, isWrapped: boolean }) => {
    return (
        <Button onClick={onClick} className="text-xs h-10 w-10 p-0">
            {isWrapped ? <MdOutlineCodeOff /> : <BsCodeSlash />}
        </Button>
    );
};

const ComponentContainer: React.FC<IComponent> = ({ component: Component }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isWrapped, setIsWrapped] = useState(false);

    const formatHTML = (html: string): string => {
        const tab = '\t';
        let result = '';
        let indent = '';

        html.split(/>\s*</).forEach(function (element) {
            if (element.match(/^\/\w/)) indent = indent.substring(tab.length); // decrease indent
            result += indent + '<' + element + '>\n';
            if (element.match(/^<?\w[^>]*[^/]$/)) indent += tab; // increase indent
        });

        return result.substring(1, result.length - 1);
    };

    const copyComponentCode = useCallback(() => {
        if (ref.current) {
            let text = ref.current.outerHTML;
            // Format the HTML before copying
            const formattedText = formatHTML(text);
            navigator.clipboard.writeText(formattedText)
                .then(() => {
                    toast.success("Copied to clipboard");
                })
                .catch(err => {
                    toast.error("Failed to copy: " + err);
                });
        }
    }, [ref]);

    const copyButton = <CopyButton onClick={copyComponentCode} />;
    const showCodeButton = <ShowCodeButton onClick={() => setIsWrapped(prev => !prev)} isWrapped={isWrapped} />;

    const ComponentButtons = <div className="flex gap-2 ml-2">{copyButton}{showCodeButton}</div>;
    return (
        <div className='mb-1'>
            <Component codeRef={ref} ComponentButtons={ComponentButtons} />
            {isWrapped && (
                <pre className='bg-slate-800 p-2 my-2 rounded-md text-white text-xs overflow-x-auto whitespace-pre-wrap break-words'>
                    <code>{ref.current?.outerHTML && formatHTML(ref.current.outerHTML)}</code>
                </pre>
            )}
        </div>
    );
};

export default ComponentContainer;

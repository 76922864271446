import React from 'react';
import ComponentContainer from './ComponentContainer';
import { IComponentElement } from '../lib/types';

export default function Colors() {
    return (
        <div>
            <ComponentContainer component={ColorsComponent} />
        </div>
    );
}

const ColorsComponent = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div>
            <div className="flex justify-between">
                <div ref={codeRef as React.RefObject<HTMLDivElement>} className="flex gap-2 flex-wrap">
                    <div className="w-10 h-10 bg-nr-gray1"></div>
                    <div className="w-10 h-10 bg-nr-gray2"></div>
                    <div className="w-10 h-10 bg-nr-gray4"></div>
                    <div className="w-10 h-10 bg-nr-gray5"></div>
                    <div className="w-10 h-10 bg-nr-gray6"></div>
                    <div className="w-10 h-10 bg-nr-gray7"></div>
                    <div className="w-10 h-10 bg-nr-gray8"></div>
                    <div className="w-10 h-10 bg-nr-pink"></div>
                    <div className="w-10 h-10 bg-nr-mahogany"></div>
                    <div className="w-10 h-10 bg-nr-black80"></div>
                    <div className="w-10 h-10 bg-nr-green1"></div>
                    <div className="w-10 h-10 bg-nr-wb20"></div>
                    <div className="w-10 h-10 bg-nr-peachBlush"></div>
                    <div className="w-10 h-10 bg-nr-bark60"></div>
                </div>
                {ComponentButtons}
            </div>
            <div className='mt-5'>
                <h5>Custom Colors in tailwind.config.js</h5>
                <p>In this component, we have created a custom set of colors for Northern Reflections and integrated them into our Tailwind CSS configuration. The custom colors are prefixed with nr- to distinguish them from Tailwind's default color palette.</p>
            </div>
        </div>

    );
};

import React from 'react'
import ComponentContainer from './ComponentContainer'
import { IComponentElement } from '../lib/types'

export default function Headings() {
  return (
    <div>
        <ComponentContainer component={Heading1} />
        <ComponentContainer component={Heading2} />
        <ComponentContainer component={Heading3} />
        <ComponentContainer component={Heading4} />
        <ComponentContainer component={Heading5} />
        <ComponentContainer component={Heading6} />
        <ComponentContainer component={Paragraph} />
        <ComponentContainer component={Small} />
    </div>
  )
}

const Heading1 = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <h1 ref={codeRef as React.RefObject<HTMLDivElement>} className="text-5xl font-bold font-playfair">Heading 1</h1>
            {ComponentButtons}
        </div>
    )
}

const Heading2 = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <h2 ref={codeRef as React.RefObject<HTMLDivElement>} className="text-4xl font-bold font-playfair">Heading 2</h2>
            {ComponentButtons}
        </div>
    )
}

const Heading3 = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <h3 ref={codeRef as React.RefObject<HTMLDivElement>} className="text-3xl font-bold font-playfair">Heading 3</h3>
            {ComponentButtons}
        </div>
    )
}

const Heading4 = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <h4 ref={codeRef as React.RefObject<HTMLDivElement>} className="text-2xl font-bold font-lato">Heading 4</h4>
            {ComponentButtons}
        </div>
    )
}

const Heading5 = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <h5 ref={codeRef as React.RefObject<HTMLDivElement>} className="text-xl font-bold font-lato">Heading 5</h5>
            {ComponentButtons}
        </div>
    )
}

const Heading6 = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <h6 ref={codeRef as React.RefObject<HTMLDivElement>} className="text-lg font-bold font-lato">Heading 6</h6>
            {ComponentButtons}
        </div>
    )
}

const Paragraph = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <p ref={codeRef as React.RefObject<HTMLDivElement>} className="text-base font-normal font-lato">Paragraph</p>
            {ComponentButtons}
        </div>
    )
}

const Small = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div className="flex justify-between">
            <p ref={codeRef as React.RefObject<HTMLDivElement>} className="text-sm font-normal font-lato">Small</p>
            {ComponentButtons}
        </div>
    )
}
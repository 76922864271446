import React from 'react';
import ComponentContainer from './ComponentContainer';
import { IComponentElement } from '../lib/types';

export default function Images() {
    return (
        <div>
            <ComponentContainer component={ProductImage} />
        </div>
    );
}

const ProductImage = ({ ComponentButtons, codeRef }: IComponentElement) => {
    return (
        <div>
            <div className="flex justify-between">
                <div className="max-w-xs">
                    <img src="/assets/product-image.jpeg" alt="Product" ref={codeRef as React.RefObject<HTMLImageElement>} className="object-cover aspect-product h-full w-full" />
                </div>
                {ComponentButtons}
            </div>
            <div className='mt-5'>
                <h5>Custom Image Aspect Ratio in tailwind.config.js</h5>
                <div>In this component, we have created a custom image aspect ratio for Northern Reflections and integrated it into our Tailwind CSS configuration. The custom aspect ratio is named aspect-product and is defined as 9:10.</div>
            </div>
        </div>
    );
};